<template>
  <header
    :class="$style.root"
    :data-sticky="sticky ? '' : null"
  >
    <slot />
  </header>
</template>

<script setup>
const props = defineProps({
  menuOpen: {
    type: Boolean,
    default: false,
  },
});

const parentEl = useParentElement()

const { y } = useScroll(parentEl)
const lastY = ref(0)
const dir = ref(null)

watchDebounced(y, (newValue, oldValue) => {
  lastY.value = newValue
  dir.value = newValue > oldValue ? 'down' : 'up'
}, { debounce: 100, maxWait: 500 })

const sticky = computed(() => {
  return dir.value === 'up' || y.value === 0 || props.menuOpen
})
</script>

<style module lang=scss>
.root {
  padding: var(--unit--vertical) var(--unit--horizontal);
  margin-bottom: var(--unit--vertical);

  position: var(--site-header--position, sticky);
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--z-index--site-header);

  transition: transform 0.25s ease-in-out;
  transform: translateY(-100%);
}

.root[data-sticky] {
  transform: translateY(0%);
}
</style>
